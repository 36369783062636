<template>
  <div>
  <div id="target" v-on:drop="dropHandler" v-on:dragover="dragoverHandler" class="chat-window">
    <div class="tabs mb-0 is-centered is-boxed">
      <div>
        <a @click="toggleOrderMenu">
          <b-icon icon="order-alphabetical-ascending" />
        </a>
      </div>
      <ul>
        <li :class="getFilterClass('ALL')">
          <a @click="changeStatusFilter('ALL')">Todos ({{ getRoomCounts('ALL') }})</a>
        </li>
        <li :class="getFilterClass('ATT')" v-if="getIsSupervisor">
          <a @click="changeStatusFilter('ATT')">Em nome de outros atendentes ({{ getRoomCounts('ATT') }})</a>
        </li>
        <li :class="getFilterClass('MYT')">
          <a @click="changeStatusFilter('MYT')">Em meu nome ({{ getRoomCounts('MYT') }})</a>
        </li>
        <li :class="getFilterClass('PEN')">
          <a @click="changeStatusFilter('PEN')">Pendentes ({{ getRoomCounts('PEN') }})</a>
        </li>
        <li :class="getFilterClass('OGP')">
          <a @click="changeStatusFilter('OGP')">Somente grupos ({{ getRoomCounts('OGP') }})</a>
        </li>
      </ul>
      <div>
        <a type="button" class="is-info" @click="toggleInternalChat" :class="{ 'blink-green': showNotification }">
          <b-icon icon="forum-outline" />Chat Interno
        </a>
      </div>
    </div>
    <b-loading v-if="loading.rooms" :is-full-page="true" v-model="loading.rooms" :can-cancel="false"></b-loading>
    <b-loading v-if="changingFilter" :is-full-page="true" v-model="changingFilter" :can-cancel="false"></b-loading>
    <b-loading v-if="selectingAttendant" :is-full-page="true" v-model="selectingAttendant"
      :can-cancel="false"></b-loading>

    <DragAndDropFiles v-if="isDragging" :selectedRoom="selectedRoom" v-on:closeDrag="closeDrag"
      :isDragging="isDragging" />

    <div class="menu-lateral">
      <div class="lateral-order" slot="dropdown" v-show="isOrderMenuActive">
        <div class="field has-addons">
          <b-button v-if="roomsOrder === 'asc'" icon-left="sort-ascending" @click="changeSorting">Ascendente</b-button>
          <b-button v-else icon-left="sort-descending" @click="changeSorting">Descendente</b-button>
        </div>
        <b-field label="Ordenar por:" size="is-small">
          <b-select v-model="selectedOrder" placeholder="Última mensagem">
            <option value="created_at">Data de criação</option>
            <option value="last_message">Última mensagem</option>
            <option value="room_name">Nome do contato</option>
            <option value="pending">Não lidas</option>
          </b-select>
        </b-field>
        <div class="buttons">
          <b-button @click="cleanOrder">Limpar</b-button>
          <b-button type="is-info" @click="orderingRooms">Ordenar</b-button>
        </div>
      </div>
        <chat-window
        class=""
        :current-user-id="getUserId"
        :rooms="getRooms"
        :room-id="selectedRoom ? selectedRoom.roomId : null"
        :messages="messages"
        height="calc(100vh - 121px)"
        @send-message="sendMessage"
        @delete-message="deleteMessage"
        @message-action-handler="messageActionHandler"
        :loading-rooms="loading.rooms"
        :rooms-loaded="loaded.rooms"
        :messages-loaded="messagesLoaded"
        @fetch-messages="fetchMessages"
        :menu-actions="menuActions"
        @open-file="openFile" :show-reaction-emojis="false"
        :message-actions="messageActions"
        @menu-action-handler="menuActionHandler"
        :show-audio="true"
        @add-room="createTicketButtonHandler"
        :show-footer="true || !!(selectedRoom && selectedRoom.attendant_id)"
        :room-info-enabled="true"
        :text-messages="{
          ROOMS_EMPTY: 'Sem tickets',
          ROOM_EMPTY: 'Nenhum ticket selecionado',
          NEW_MESSAGES: 'Novas mensagens',
          MESSAGE_DELETED: 'Aguardando mensagem, isso pode demorar um pouco', // waiting message
          MESSAGES_EMPTY: 'Nenhuma mensagem',
          CONVERSATION_STARTED: 'A conversa começou em:',
          TYPE_MESSAGE: 'Digite sua mensagem',
          SEARCH: 'Procurar ticket',
          IS_ONLINE: 'Está online',
          LAST_SEEN: 'Última visualização ',
          IS_TYPING: 'Está digitando...'
        }"
        :audioSampleRate="48000"
        :text-formatting="{ underline: null, italic: '_', bold: '*', strike: '~', multilineCode: '```' }"
        :rooms-order="roomsOrder"
        :accepted-files="acceptedFiles"
        />
      <div class="lateral-search" v-show="isSearchRoomMessagesOpen">
        <SearchRoomMessages
          :foundMessages="foundMessages"
          :isSearching="searchingMessages"
          @handleSearchMessage="handleSearchMessage"
          @closeMessageSearch="closeMessageSearch"
          @scrollToMessage="getMessagesFromSearch"
        />
      </div>
    </div>
    <b-modal v-model="isShowingPhoto">
      <p class="image 16by9">
        <img :src="urlPhotoShowing" />
      </p>
    </b-modal>
    <ModalTransferTicket v-if="isModalTransferTicketOpen" :attendants="allAttendantsEnabled"
      :departments="allDepartmentsEnabled" v-on:attendantSelected="handleTransferAttendantSelected"
      v-on:closeModal="closeModal" :isComponentModalActive="isModalTransferTicketOpen" />

    <ModalCreateTicket v-if="isModalCreateTicketOpen" modalTitle="Selecionar contato para criar novo ticket"
      :contacts="allContacts" :settings="allSettingsEnabled" :departments="userDepartments" :templates="getTemplates" :isCreatingNewTicket="true"
      v-on:contactSelected="countContactPendingSurveys" v-on:closeModal="closeModal"
      :isComponentModalActive="isModalCreateTicketOpen" v-on:addContact="addContactButtonHandler" />

    <ModalCreateTicket v-if="isModalSendContactOpen" modalTitle="Selecionar contato a ser enviado" :contacts="allContacts"
      :isCreatingNewTicket="false" v-on:contactSelected="contactToSendSelectedHandler" v-on:closeModal="closeModal"
      :isComponentModalActive="isModalSendContactOpen" v-on:addContact="addContactButtonHandler" />

    <ModalSelectTicket v-if="isModalForwardMessageOpen" modalTitle="Selecionar o contato para encaminhar a mensagem"
      :tickets="allContacts" v-on:contactSelected="forwardMessageToTicket" v-on:closeModal="closeModal"
      :isComponentModalActive="isModalForwardMessageOpen" :settings="allSettingsEnabled" :departments="userDepartments"
      v-on:findContactTicket="findContactTicket" :contactTicket="contactTicket" />

    <ModalSelectTicket v-if="isModalForwardMessageFromInternalChatOpen" modalTitle="Selecionar o contato para encaminhar a mensagem"
    :tickets="allContacts" v-on:contactSelected="sendMessageFromInternalChat" v-on:closeModal="closeModal"
    :isComponentModalActive="isModalForwardMessageFromInternalChatOpen" :settings="allSettingsEnabled" :departments="userDepartments"
    v-on:findContactTicket="findContactTicket" :contactTicket="contactTicket" :onlyWwebjs="false" />

    <ModalTicketDetails v-if="isModalTicketDetailsOpen" :ticket="selectedRoom" :messages="messages"
      :showMessagesOption="false" :tags="allEnabledTags" :contactTags="allContactTags ? allContactTags : []"
      v-on:closeModal="closeModal" :isComponentModalActive="isModalTicketDetailsOpen"
      v-on:updateContact="handleUpdateContact" />

    <ModalCloseTicket v-if="isModalCloseTicketOpen" :ticket="selectedRoom" :loading="fetchingSettings || fetchingReasons"
      :reasons="allReasons" :settings="settings" v-on:closeModal="closeModal" v-on:closeTicket="closeTicketHandler"
      :isComponentModalActive="isModalCloseTicketOpen" />

    <ModalInsertContact v-if="isModalAddContactOpen" v-on:addContact="handleAddContact" v-on:closeModal="closeModal"
      v-on:updateContact="handleUpdateContact" :isComponentModalActive="isModalAddContactOpen"
      :contactUpdating="contactAdding" :canLink="false" :contactTags="allContactTags" :tags="allEnabledTags" />

    <ModalSendDefaultMessages v-if="isModalDefaultMessagesOpen" :isComponentModalActive="isModalDefaultMessagesOpen"
      :defaultMessages="allDefaultMessages" :ticket="selectedRoom" v-on:closeModal="closeModal"
      v-on:sendDefaultMessage="handleSendDefaultMessage" />

    <ModalDeleteMessage v-if="isModalDeleteMessageOpen" :isComponentModalActive="isModalDeleteMessageOpen"
      :message="messageToDelete" v-on:closeModal="closeModal" v-on:deleteMessage="handleDeleteMessage" />

    <ModalContactCards v-if="isModalContactCardsOpen" :isComponentModalActive="isModalContactCardsOpen"
      :contacts="contactCards" v-on:closeModal="closeModal" v-on:addContact="addContactButtonHandler" />

    <ModalSendTemplate v-if="isModalSendTemplateOpen" :isComponentModalActive="isModalSendTemplateOpen"
      :templates="getTemplates" :loading="fetchingTemplates" v-on:closeModal="closeModal"
      v-on:sendTemplate="handleSendTemplate" />

    <ModalSetContactAttendant v-if="isModalSetContactAttendantOpen" :ticket="selectedRoom" :attendant_id="getUserId" v-on:closeModal="closeModal" />

    <!-- b-dropdown id="chatDropdownMenu" aria-role="list" position="is-top-right" style="display: none">
      <template #trigger>
        <b-icon class="button-icon vac-svg-button vac-col-messages" role="button" icon="share-variant">
        </b-icon>
      </template>

      < b-dropdown-item aria-role="listitem" @click="sendContactHandler">
        <b-icon icon="account" size="is-small"></b-icon> Enviar
        contato
      </b-dropdown-item>
    </b-dropdown -->
  </div>
    <div>
      <InternalChat :isInternalChatActive="isInternalChatActive" v-on:closeInternalChat="toggleInternalChat" v-on:notification="handleInternalChatNotification" v-on:sendToWhatsApp="forwardMessageFromInternalChat"/>
    </div>
  </div>
</template>

<script>
/* eslint-disable no-unused-expressions */
/* eslint-disable no-unused-expressions */
/* eslint-disable camelcase */
import ChatWindow from 'vue-advanced-chat'
import 'vue-advanced-chat/dist/vue-advanced-chat.css'
import { get, post, getCancelable, pureBlobGet, pureGet, getUrl } from '../utils/api'
import { mapGetters, mapActions } from 'vuex'
import mixin from '../utils/mixins'
import { Howl } from 'howler'
import ModalTransferTicket from '@/components/modals/ModalTransferTicket'
import ModalCreateTicket from '@/components/modals/ModalCreateTicket'
import ModalTicketDetails from '@/components/modals/ModalTicketDetails'
import ModalCloseTicket from '@/components/TicketsCloseReason/ModalCloseTicket'
import ModalSendDefaultMessages from '@/components/modals/ModalSendDefaultMessages'
import DragAndDropFiles from '@/components/chat/DragAndDropFiles'
import axios from 'axios'
import ifvisible from 'ifvisible.js'
import { isAudioFile, isVideoFile, isImageFile, isLargeFile, getTemporaryId } from '@/utils/util'
import ModalInsertContact from '@/components/modals/ModalInsertContact'
import ModalDeleteMessage from '@/components/modals/ModalDeleteMessage'
import ModalSelectTicket from '@/components/modals/ModalSelectTicket'
import ModalContactCards from '@/components/modals/ModalContactCards'
import SearchRoomMessages from '@/components/chat/SearchRoomMessages.vue'
import ModalSendTemplate from '@/components/modals/ModalSendTemplate.vue'
import ModalSetContactAttendant from '@/components/modals/ModalSetContactAttendant.vue'
import { nextTick } from 'vue'
import { API_TYPE } from '../utils/constants'
import InternalChat from '../components/chat/InternalChat.vue'

const DEFAULT_MENU_ACTIONS = [
  {
    name: 'searchMessage',
    title: 'Buscar mensagem'
  },
  {
    name: 'closeTicket',
    title: 'Fechar Ticket'
  },
  {
    name: 'transferTicket',
    title: 'Transferir ticket'
  },
  {
    name: 'showTicketDetails',
    title: 'Detalhes do ticket'
  },
  {
    name: 'defaultMessages',
    title: 'Mensagens predefinidas'
  },
  {
    name: 'sendTemplate',
    title: 'Enviar template'
  },
  
]

const NON_CONTACT_MENU_ACTIONS = [
  ...DEFAULT_MENU_ACTIONS,
  {
    name: 'addContact',
    title: 'Adicionar contato'
  }
]

const CONTACT_MENU_ACTIONS = [
  ...DEFAULT_MENU_ACTIONS,
  {
    name: 'setContactAttendant',
    title: 'Encarteirar contato'
  }
]

const CLOUD_API_MESSAGE_ACTIONS = [
  {
    name: 'replyMessage',
    title: 'Reply'
  },
  {
    name: 'addContact',
    title: 'Adicionar Contato'
  },
  {
    name: 'downloadFile',
    title: 'Baixar Arquivo'
  },
  {
    name: 'showMessage',
    title: 'Ver mensagem'
  }
]

const DEFAULT_MESSAGE_ACTIONS = [
  {
    name: 'replyMessage',
    title: 'Reply'
  },
  {
    name: 'deleteMessage',
    title: 'Apagar',
    onlyMe: true
  },
  {
    name: 'forwardMessage',
    title: 'Encaminhar mensagem'
  },
  {
    name: 'addContact',
    title: 'Adicionar Contato'
  },
  {
    name: 'downloadFile',
    title: 'Baixar Arquivo'
  },
  {
    name: 'showMessage',
    title: 'Ver mensagem'
  }
]

const OFICIAL_ACCEPTED_AUDIO_TYPES = 'audio/aac, audio/mp4, audio/mpeg, audio/amr, audio/ogg'
const OFICIAL_ACCEPTED_DOCUMENT_TYPES = 'text/plain, application/pdf, application/vnd.ms-powerpoint, application/msword, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.openxmlformats-officedocument.presentationml.presentation, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
const OFICIAL_ACCEPTED_IMAGE_TYPES = 'image/jpeg, image/png'
const OFICIAL_ACCEPTED_VIDEO_TYPES = 'video/mp4, video/3gp'
const OFICIAL_ACCEPTED_FILES = OFICIAL_ACCEPTED_AUDIO_TYPES + ', ' + OFICIAL_ACCEPTED_DOCUMENT_TYPES + ', ' + OFICIAL_ACCEPTED_IMAGE_TYPES + ', ' + OFICIAL_ACCEPTED_VIDEO_TYPES

export default {
  components: {
    ChatWindow,
    ModalTransferTicket,
    ModalCreateTicket,
    ModalTicketDetails,
    ModalCloseTicket,
    DragAndDropFiles,
    ModalSendDefaultMessages,
    ModalInsertContact,
    ModalDeleteMessage,
    ModalSelectTicket,
    ModalContactCards,
    SearchRoomMessages,
    ModalSendTemplate,
    InternalChat,
    ModalSetContactAttendant
  },
  mixins: [mixin],
  data () {
    return {
      statusFilter: 'ALL',
      availableAttendants: [],
      isModalTransferTicketOpen: false,
      isModalCreateTicketOpen: false,
      isModalSendContactOpen: false,
      isModalTicketDetailsOpen: false,
      isModalCloseTicketOpen: false,
      isModalDefaultMessagesOpen: false,
      isModalAddContactOpen: false,
      isModalDeleteMessageOpen: false,
      isModalForwardMessageOpen: false,
      isModalContactCardsOpen: false,
      isModalSetContactAttendantOpen: false,
      loadingChat: false,
      messagesLoaded: true,
      selectedRoom: null,
      selectingAttendant: false,
      selectAttentantElement: null,
      sendTemplateElement: null,
      blockedMessageElement: null,
      contactAdding: null,
      closeReasons: [],
      messagesPage: 1,
      rooms: [],
      messages: [],
      isShowingPhoto: false,
      urlPhotoShowing: '',
      menuActions: DEFAULT_MENU_ACTIONS,
      loading: {
        rooms: false
      },
      loaded: {
        rooms: true
      },
      changingFilter: false,
      axiosSource: null,
      isDragging: false,
      messageToDelete: null,
      messageToForward: null,
      contactCards: [],
      requestQueue: [],
      settings: [],
      contactTicket: null,
      roomsOrder: 'desc',
      isOrderMenuActive: false,
      selectedOrder: '',
      messageActions: [],
      isSearchRoomMessagesOpen: false,
      foundMessages: [],
      searchingMessages: false,
      isModalSendTemplateOpen: false,
      acceptedFiles: '*',
      isInternalChatActive: false,
      disableDownloadContactsImageDownload: false,
      tokenCheckInterval: null,
      showNotification: false,
      forwardingMessage: null,
      isModalForwardMessageFromInternalChatOpen: false
    }
  },
  computed: {
    ...mapGetters([
      'allContacts',
      'allAttendants',
      'getToken',
      'getUserId',
      'getIsSupervisor',
      'getIsSupervisorAllDepartments',
      'getName',
      'getStatus',
      'allReasons',
      'allSettingsEnabled',
      'allDefaultMessages',
      'fetchingDefaultMessages',
      'allTags',
      'allEnabledTags',
      'allContactTags',
      'fetchingReasons',
      'fetchingSettings',
      'allAttendantsEnabled',
      'allDepartmentsEnabled',
      'getUserDepartments',
      'allTemplates',
      'fetchingTemplates'
    ]),
    getRooms () {
      switch (this.statusFilter) {
        case 'ALL':
          return this.rooms.map(room => room).sort(this.sortChat)
        case 'ATT':
          return this.rooms.filter(
            room => !!room.attendant_id && room.attendant_id !== this.getUserId
          ).sort(this.sortChat)
        case 'MYT':
          return this.rooms.filter(
            room => room.attendant_id === this.getUserId
          ).sort(this.sortChat)
        case 'PEN':
          return this.rooms.filter(room => !room.attendant_id).sort(this.sortChat)
        case 'OGP':
          return this.rooms.filter(room => room.is_group === true).sort(this.sortChat)
        case 'TIC':
          return this.rooms.filter(room => room.is_group === false).sort(this.sortChat)
        default:
          return this.rooms.map(room => room).sort(this.sortChat)
      }
    },
    getAttendants () {
      return this.allAttendantsEnabled.filter(att => (att.status === 'online' || att.status === 'invisible'))
    },
    userDepartments () {
      if (this.getIsSupervisorAllDepartments) {
        if (this.allDepartmentsEnabled.length === 0) this.fetchDepartments()
        return this.allDepartmentsEnabled
      } else {
        if (!this.getUserDepartments || typeof this.getUserDepartments === 'string') {
          this.fetchUserDetails(this.getToken)
        }
        return this.getUserDepartments
      }
    },
    getTemplates () {
      return this.allTemplates.filter(template => template.status === 'APPROVED')
    }
  },
  created () {
    this.canLink = this.getIsSupervisorAllDepartments
    this.messageActions = DEFAULT_MESSAGE_ACTIONS
    this.acceptedFiles = '*'
    this.fetchTemplates()
    this.fetchDepartments()
  },
  methods: {
    ...mapActions([
      'fetchAttendants',
      'fetchDefaultMessagesWithoutFile',
      'createNewTicket',
      'fetchEnabledReasons',
      'fetchSettings',
      'addContact',
      'fetchTags',
      'fetchContactTags',
      'fetchEnabledTags',
      'addContactTags',
      'fetchDepartments',
      'fetchUserDetails',
      'fetchTemplates',
      'deleteToken'
    ]),
    async checkToken () {
      const url = new URL(process.env.VUE_APP_GTCHAT_URL)
      const path = url.pathname
      const cookieToken = document.cookie.split('; ').find(row => row.startsWith(`token-${url.hostname + path}`)).split('=')[1]
      if (cookieToken) {
        try {
          await axios.post(`${process.env.VUE_APP_GTCHAT_URL}/sessions/validate`, {}, {
            headers: {
              Authorization: `Bearer ${cookieToken}`
            }
          })
        } catch (e) {
          if (e.isAxiosError && e.response.status === 401) {
            this.$buefy.snackbar.open({
              message: 'A sessão expirou. Por favor, faça login novamente.',
              type: 'is-warning',
              position: 'is-top',
              actionText: 'Ir para Login',
              indefinite: true,
              onAction: () => {
                this.deleteToken()
                this.$router.push({ name: 'login' })
              }
            })
          }
        }
      }
    },
    toggleOrderMenu () {
      this.isOrderMenuActive = !this.isOrderMenuActive
    },
    toggleInternalChat () {
      this.isInternalChatActive = !this.isInternalChatActive
    },
    orderingRooms () {
      this.setLoading(true)
      this.rooms = []
      this.selectedRoom = null
      this.messages = []
      this.toggleOrderMenu()
      this.fetchRooms(this.selectedOrder)
    },
    changeSorting () {
      this.roomsOrder = this.roomsOrder === 'desc' ? 'asc' : 'desc'
    },
    cleanOrder () {
      this.setLoading(true)
      this.rooms = []
      this.selectedRoom = null
      this.messages = []
      this.toggleOrderMenu()
      this.fetchRooms()
    },
    async handleCreateTicket (data, survey_tkt_id) {
      const { contact, department, ww_phone } = data
      this.setLoading(true)
      try {
        const ticketCreated = await this.createNewTicket({
          client_phone: contact.phone,
          client_name: contact.name,
          phone_from: ww_phone,
          department_id: department.department_id || department.id,
          survey_tkt_id: survey_tkt_id
        })
        this.closeModal()
        await this.fetchRooms()

        this.selectedRoom = this.rooms.find(
          ro => ro.roomId === ticketCreated.id
        )
        this.setupTicketMenu()
      } catch (error) {
        this.$buefy.dialog.confirm({
          title: 'Erro ao abrir o ticket',
          message: `${error.message}`,
          type: 'is-danger',
          hasIcon: true,
          cancelText: 'Fechar'
        })
      } finally {
        this.setLoading(false)
      }
    },
    async countContactPendingSurveys (data) {
      getCancelable(
        'satisfaction-surveys/pending',
        this.axiosSource.token,
        {
          ww_phone: data.ww_phone,
          contact_id: data.contact.id,
          client_phone: data.contact.phone
        }
      )
        .then(r => {
          if (r.data.survey_tkt_id) {
            this.$buefy.dialog.confirm({
              title: 'Pesquisa de satisfação em andamento',
              message: 'A pesquisa de satisfação está sendo feita com o contato selecionado. Deseja <b>interromper</b> a pesquisa e criar o novo ticket?',
              confirmText: 'Interromper pesquisa',
              type: 'is-danger',
              size: 'is-big',
              hasIcon: true,
              cancelText: 'Cancelar',
              onConfirm: () => {
                this.handleCreateTicket(data, r.data.survey_tkt_id)
              }
            })
          } else {
            this.handleCreateTicket(data, null)
          }
        })
        .catch(thrown => {
          if (axios.isCancel(thrown)) {
          } else {
            // handle error
          }
        })
    },
    async handleSendDefaultMessage (defaultMessage) {
      this.closeModal()

      post('messages/predefined_message', {
        ticket_id: this.selectedRoom.id,
        predefined_message_id: defaultMessage.id
      })
    },
    async closeDrag () {
      this.isDragging = false
    },
    dropHandler (event) { },
    dragoverHandler (event) {
      if (this.selectedRoom.attendant_id) {
        this.isDragging = true
      } else {
        this.$buefy.dialog.alert({
          title: 'Erro ao Enviar Arquivo',
          message:
            'O <b>ticket</b> não foi selecionado. Selecione o <b>ticket</b> antes de enviar o(s) arquivo(s)!',
          type: 'is-danger',
          hasIcon: true,
          icon: 'times-circle',
          iconPack: 'fa',
          ariaRole: 'alertdialog',
          ariaModal: true
        })
      }
    },
    sortChat (roomA, roomB) {
      const timestampA = roomA.last_message ?? roomA.created_at
      const timestampB = roomB.last_message ?? roomB.created_at
      if (timestampA > timestampB) {
        return -1
      }
      if (timestampA < timestampB) {
        return 1
      }
      // a deve ser igual a b
      return 0
    },
    async contactToSendSelectedHandler (contact) {
      this.closeModal()
      this.$socket.emit('user_message', {
        contact_name: contact.name,
        contact_phone: contact.phone,
        ticket_id: this.selectedRoom.roomId
      })
    },
    async forwardMessageToTicket (data) {
      const { contact, department, ww_phone } = data
      if (this.messageToForward.isDeleted) {
        this.$buefy.toast.open(
          'Não é possível encaminhar uma mensagem já excluida!'
        )
        this.closeModal()
        return
      }

      let ticket = this.rooms.filter(r => {
        const contact_id = r.contact ? r.contact.id : r.contact_id
        const department_id = r.department ? r.department.id : r.department_id
        return r.ww_phone === ww_phone && contact_id === contact.id && department_id === department.id
      })[0]
      if (!ticket) {
        try {
          this.setLoading(true)
          ticket = await this.createNewTicket({
            client_phone: contact.phone,
            client_name: contact.name,
            phone_from: ww_phone,
            department_id: department.id || department.department_id
          })

          await this.fetchRooms()

          this.selectedRoom = this.rooms.find(
            ro => ro.roomId === ticket.id
          )
          this.setupTicketMenu()
        } catch (error) {
          this.$buefy.dialog.confirm({
            title: 'Erro ao encaminhar mensagem',
            message: `${error.message}`,
            type: 'is-danger',
            hasIcon: true,
            cancelText: 'Fechar'
          })
        } finally {
          this.setLoading(false)
        }
      }

      if (ticket) {
        await post(`tickets/forward/${this.messageToForward}/${ticket.id}`)
        this.closeModal()
      }
    },
    async handleTransferAttendantSelected (data) {
      this.closeModal()
      try {
        this.setLoading(true)
        await post(`tickets/transferTicket/${this.selectedRoom.roomId}`, {
          department_id_to: data.department.id,
          attendant_id_to: data.attendant?.id,
          department_id_from: this.selectedRoom.department_id,
          note: data.note ?? ''
        })
      } finally {
        this.setLoading(false)
      }
    },
    playSound () {
      try {
        const sound = new Howl({
          src: require('../assets/alert.wav'),
          volume: 0.1
        })
        sound.play() 
      } catch (error) {
        console.warn('Error playing sound', error)
      }
    },
    blobToBase64 (blob) {
      const reader = new FileReader()
      reader.readAsDataURL(blob)
      return new Promise(resolve => {
        reader.onloadend = () => {
          resolve(reader.result)
        }
      })
    },
    async sendMessage ({ content, roomId, files, replyMessage }) {
      if (replyMessage && replyMessage.isDeleted) {
        this.$buefy.toast.open(
          'Não é possível fazer reply de uma mensagem já excluida!'
        )
        return
      }

      if (replyMessage && this.messages.length > 50) {
        const msgIndex = this.messages.findIndex(el => el.whatsapp_message_id === replyMessage.whatsapp_message_id)
        if (msgIndex >= 0 && msgIndex <= (this.messages.length - 51)) {
          this.$buefy.toast.open(
            'Não é possível fazer reply de mensagens antigas!'
          )
        }
        return
      }

      if (files) {
        for (let i = 0; i < files.length; i++) {
          const base64 = await this.blobToBase64(files[i].blob)
          const file_base64 = base64.substr(base64.indexOf(',') + 1)
          let fileName = files[i].name
          if (files[i].extension) fileName = `${files[i].name}.${files[i].extension}`

          if (isLargeFile(files[i].size)) {
            this.$buefy.snackbar.open({
              message: `<b>O arquivo ${fileName} é muito grande</b>. <br> Não é possível enviar arquivos maiores que 20MB`,
              type: 'is-danger',
              position: 'is-bottom-right',
              queue: false,
              duration: 8000
            })
          } else {
            try {
              this.changingFilter = true
              const temporaryId = getTemporaryId();

              const newMessage = {
                _id: temporaryId,
                id: temporaryId,
                temporary_id: temporaryId,
                from: this.getUserId,
                timestamp: `${this.formatDate(new Date())} - ${this.formatTime(new Date())}`,
                senderId: this.getUserId,
                content,
                files: [
                  {
                    name: fileName,
                    type: files[i].extension,
                    audio: true,
                    duration: 14.4,
                    url: `data:${files[i].type};base64,${file_base64}`,
                    progress: 0
                  }
                ],
                deleted: false,
                isDeleted: false,
                username: null,
                disable_actions: true,
                disable_reactions: true,
                saved: true
              }

              this.messages = [
                ...this.messages,
                newMessage
              ]

              post('messages/user_message', {
                message: content,
                ticket_id: roomId,
                file_base64,
                file_mimetype: files[i].type,
                file_name: fileName,
                reply_message_id: replyMessage?.whatsapp_message_id ?? null,
                temporary_id: temporaryId
              }, (progressEvent) => {
                const { loaded, total } = progressEvent;
                let percent = Math.floor(((loaded * 100) / total) / 2);
                newMessage.files[0].progress = percent
              }).catch(err => console.log(err))
            } catch (error) {
              console.warn(error)
              this.changingFilter = false
            } finally {
              this.changingFilter = false
            }
          }
        }
      } else {
        const temporaryId = getTemporaryId();

        post('messages/user_message', {
          message: content,
          ticket_id: roomId,
          reply_message_id: replyMessage?.whatsapp_message_id ?? null,
          temporary_id: temporaryId
        }, (progressEvent) => {
          const { loaded, total } = progressEvent;
          let percent = Math.floor(((loaded * 100) / total));

          if (percent === 100) {
            const newMessage = {
              _id: temporaryId,
              id: temporaryId,
              temporary_id: temporaryId,
              from: this.getUserId,
              timestamp: `${this.formatDate(new Date())} - ${this.formatTime(new Date())}`,
              senderId: this.getUserId,
              content: `*${this.getName}*\n${content}`,
              deleted: false,
              isDeleted: false,
              username: null,
              disable_actions: true,
              disable_reactions: true,
              saved: false
            }

            this.messages = [
              ...this.messages,
              newMessage,
            ]
          }
        }).catch(err => console.log(err))
      }
    },
    handleInternalChatNotification () {
      this.showNotification = true

      setTimeout(() => {
        this.showNotification = false
      }, 5000)
    },
    async messageActionHandler ({ roomId, action, message }) {
      switch (action.name) {
        case 'forwardMessage':
          this.forwardMessage(message)
          break
        case 'addContact':
          if (message.isDeleted) {
            this.$buefy.toast.open(
              'Não é possível adicionar contato de uma mensagem apagada'
            )
            return
          }
          if (message.sharedContact && ((message.from.length > 13 || this.selectedRoom.is_group) && message.whatsapp_message_id.indexOf('false') === 0 && message.whatsapp_message_phone && message.whatsapp_sender_name)) {
            if (message.sharedContact.length > 1) {
              this.addContactButtonHandler([...message.sharedContact, { name: message.whatsapp_sender_name, phone: message.whatsapp_message_phone }])
            } else {
              this.addContactButtonHandler([message.sharedContact, { name: message.whatsapp_sender_name, phone: message.whatsapp_message_phone }])
            }
          } else if (message.sharedContact) {
            this.addContactButtonHandler(message.sharedContact)
          } else if ((message.from.length > 13 || this.selectedRoom.is_group) && message.whatsapp_message_id.indexOf('false') === 0 && message.whatsapp_message_phone && message.whatsapp_sender_name) {
            this.addContactButtonHandler({ name: message.whatsapp_sender_name, phone: message.whatsapp_message_phone })
          } else {
            this.$buefy.toast.open(
              'Não há contato nesta mensagem'
            )
          }
          break
        case 'downloadFile':
          this.downloadFile(message)
          break
        case 'showMessage':
          if (message.whatsapp_quoted_message || (message.quotedMsg && message.quotedMsg?.messageDB)) {
            try {
              this.getMessagesFromSearch(message.whatsapp_quoted_message?.id ?? message.quotedMsg?.messageDB?.id)
            } catch (error) {
              this.$buefy.toast.open('Mensagem não encontrada')
            }
          } else {
            this.$buefy.toast.open('Não há reply nesta mensagem')
          }
          break
      }
    },
    async forwardMessage (message) {
      if (message.isDeleted) {
        this.$buefy.toast.open(
          'Não é possível encaminhar uma mensagem já excluida!'
        )
        return
      }

      this.messageToForward = message.id

      if (message.from.length > 20 && this.messages.length > 50) { // valida somente se for mensagem enviada pelo contato
        const msgIndex = this.messages.findIndex(el => el.whatsapp_message_id === message.whatsapp_message_id)
        if (msgIndex >= 0 && msgIndex <= (this.messages.length - 51)) {
          this.$buefy.toast.open(
            'Não é possível encaminhar esta mensagem!'
          )
        }
        return
      }

      await this.fetchSettings()
      this.contactTicket = null
      this.isModalForwardMessageOpen = true
    },
    findContactTicket (data) {
      const { contact, ww_phone } = data
      this.contactTicket = this.rooms.filter(el => el.contact_id === contact.id && el.ww_phone === ww_phone)
    },
    async deleteMessage ({ roomId, message }) {
      if (message.isDeleted) {
        this.$buefy.toast.open(
          'Não é possível excluir uma mensagem já excluida!'
        )
        return
      }

      this.messageToDelete = message.id

      const now = new Date()
      const sentDate = new Date(message.whatsapp_timestamp)
      const limit = sentDate.setMinutes(sentDate.getMinutes() + 30)

      if (sentDate && now >= limit) {
        this.$buefy.toast.open(
          'Não é possível apagar essa mensagem'
        )
        return
      }

      this.isModalDeleteMessageOpen = true
    },
    downloadFile (message) {
      if (!message.files) {
        this.$buefy.toast.open(
          'Não é possível baixar arquivo de mensagem sem arquivo! Faça o download primeiro do arquivo.'
        )
      } else {
        if (isVideoFile(message.files[0].type)) {
          this.$buefy.toast.open(
            'Utilize a opção de download dentro do player de vídeo.'
          )
          return
        }
        const a = document.createElement('a')
        a.href = message.files[0].url
        let ext
        try {
          if (isAudioFile(message.files[0].type)) {
            ext = '.mp3'
          } else {
            ext = message.files[0].type.split('/')[1]
          }
        } catch (error) {
          ext = ''
        }
        a.download = `${message.files[0].name + '.' + ext ?? 'download.' + ext}`
        a.click()
      }
    },
    updateTicketObject (ticket, newTicketData) {
      ticket.attendant_id = newTicketData.attendant_id
      ticket.attendant = newTicketData.attendant
      ticket.lastMessage = {
        content: this.getTicketContent(newTicketData)
      }
      ticket.last_message = newTicketData.last_message ?? newTicketData.created_at

      this.applyStyleToOpenTickets([ticket])
    },
    async updateRooms (room, showMessage = true) {
      const r = this.rooms.find(ro => ro.roomId === room.id)

      if (!r) {
        const newRoom = await this.adaptTicketToRoom(room)

        if (this.selectedRoom && this.selectedRoom.id !== newRoom.id) {
          this.selectedRoom.fetchMessages = false
        }

        this.$set(this.rooms, this.rooms.length, newRoom)

        if (showMessage) {
          this.$buefy.toast.open(`${newRoom.roomName} abriu um novo ticket`)
        }
      } else {
        const lastMessage = room.last_message ?? room.created_at

        switch (this.selectedOrder) {
          case 'created_at':
            r.index = room.created_at
            break
          case 'last_message':
            r.index = lastMessage
            break
          case 'room_name':
            r.index = this.getRoomName(room).toLowerCase()
            break
          case 'pending':
            r.index = room.unread_count
            break
          default:
            r.index = lastMessage
            break
        }

        r.last_message = lastMessage
        r.lastMessage.timestamp = this.datesAreOnSameDay(new Date(Date.now()), new Date(r.last_message)) ? `${this.formatTime(r.last_message)}` : `${this.formatDate(r.last_message)}`
        if (r.contact) {
          r.contact.last_message_sent_at = room.contact.last_message_sent_at || lastMessage
        }
        r.send_message_blocked = room.send_message_blocked
        r.expiration_date = room.expiration_date
        if (!this.selectedRoom || r.roomId !== this.selectedRoom.roomId) {
          r.unreadCount = room.unread_count
        }

        if (r.attendant_id !== room.attendant_id) {
          this.updateTicketObject(r, room)
        }
      }
    },
    messageReceived (newMessage, alert = false) {
      if (
        Notification.permission !== 'denied' ||
        Notification.permission === 'default'
      ) {
        Notification.requestPermission()
      }
      const ticketId = newMessage.ticket?.id || newMessage.ticket_id
      if (newMessage.ticket) {
        if (newMessage.whatsapp_timestamp && (newMessage.ticket.last_message < newMessage.whatsapp_timestamp || !newMessage.ticket.last_message)) {
          newMessage.ticket.last_message = newMessage.whatsapp_timestamp
        }
        this.updateRooms(newMessage.ticket)
      } else {
        const r = this.rooms.find(ro => ro.roomId === newMessage.ticket_id)
        if (r) {
          r.last_message = newMessage.ticketLastMessage
          r.lastMessage.timestamp = this.datesAreOnSameDay(new Date(Date.now()), new Date(r.last_message)) ? `${this.formatTime(r.last_message)}` : `${this.formatDate(r.last_message)}`
        }
      }
      if (ifvisible.now('hidden')) {
        if (alert) {
          this.notificationBrowser(newMessage)
          this.playSound()
        }
      }

      if (!this.selectedRoom || this.selectedRoom.roomId !== ticketId) {
        if (alert) {
          this.playSound()
        }
        return
      }

      if (this.selectedRoom && this.selectedRoom.roomId === ticketId) {
        this.applyStyleToMessageContainer(this.selectedRoom)
      }

      if (newMessage.temporary_id) {
        if (this.selectedRoom.roomId !== ticketId) return;

        let localMessageIndex = this.messages.findIndex(message => message.temporary_id === newMessage.temporary_id);

        if (localMessageIndex > -1) {
          this.messages = this.messages.map(message => {
            if (message.temporary_id === newMessage.temporary_id) {
              return this.adaptMessageToMessage(newMessage, this.getUserId, true)
            } else {
              return message
            }
          }).filter(msg => msg !== undefined)
        } else {
          this.messages = [
            ...this.messages,
            this.adaptMessageToMessage(newMessage, this.getUserId)
          ].filter(msg => msg !== undefined)
        }
      }
      else {
        this.messages = [
          ...this.messages,
          this.adaptMessageToMessage(newMessage, this.getUserId)
        ].filter(msg => msg !== undefined)
      }

      this.$socket.emit('client_message_read', { ticket_id: ticketId })
    },
    messageDeleted (messageDeleted) {
      this.messages = this.messages.map(message => {
        if (message.whatsapp_message_id === messageDeleted.whatsapp_message_id) {
          const newMessage = JSON.parse(JSON.stringify(message))

          newMessage.deleted = messageDeleted.deleted
          newMessage.deleted_at = messageDeleted.deleted_at
          newMessage.user_deleted_by = messageDeleted.user_deleted_by

          return this.adaptMessageToMessage(newMessage, this.getUserId, true)
        }

        return message
      })
      if (messageDeleted.whatsapp_message_id.search(messageDeleted.user_deleted_by) !== -1) {
        this.$buefy.toast.open(
          messageDeleted.client_name + ' apagou uma mensagem'
        )
      } else {
        if (messageDeleted.everyone) {
          this.$buefy.toast.open(
            'Mensagem apagada para todos'
          )
        } else {
          this.$buefy.toast.open(
            'Mensagem apagada para você'
          )
        }
      }
    },
    notificationBrowser (message) {
      const options = {
        body: /BEGIN:VCARD((.|\n)*?)END:VCARD/gm.test(message.message) ? 'Contato' : 'Mensagem: ' + message.message,
        silent: true,
        icon: '/img/icons/android-chrome-512x512.png'
      }

      if (Notification.permission === 'granted') {
        const notification = new Notification(
          'Nova mensagem recebida',
          options
        )

        notification.onclick = e => {
          e.preventDefault()
          window.focus()
          notification.close()
        }
      }
    },
    scrollMessageContainerToBottom () {
      const messageContainer = document.querySelector('.vac-container-scroll')
      // eslint-disable-next-line no-return-assign
      this.$nextTick(
        () => (messageContainer.scrollTop = messageContainer.scrollHeight)
      )
    },
    messageStatusChanged (statusData) {
      setTimeout(() => {
        if (this.selectedRoom.id === statusData.ticket_id) {
          console.log('message_status_changed', statusData)
          this.messages = this.messages.map(message => {
            const msg = message
            if (msg.whatsapp_message_id === statusData.whatsapp_message_id) {
              msg.saved = statusData.saved
              msg.distributed = statusData.distributed
              msg.seen = statusData.seen
              msg.failure = statusData.failure || statusData.failed || false
            }

            return msg
          })

          this.scrollMessageContainerToBottom()
        }
      }, 2000)
    },
    messageProgressChanged ({ temporary_id, progress}) {
      const message = this.messages.find(message => message.temporary_id === temporary_id)
      if (message && message.files) {
        message.files[0].progress = Math.floor(50 + (progress / 2))
      }
    },
    ticketTransfered (data) {
      data.ticket.attendant = {
        id: this.getUserId,
        name: this.getName
      }
      if (data.ticket) this.updateRooms(data.ticket, false)
      if (alert) this.playSound()
      this.$buefy.toast.open('Atenção, você recebeu um ticket transferido!')
    },
    ticketReceived (data) {
      data.ticket.attendant = {
        id: this.getUserId,
        name: this.getName
      }
      if (data.ticket) this.updateRooms(data.ticket, false)
      if (alert) this.playSound()
      this.$buefy.toast.open('Atenção, você recebeu um ticket da sua carteira de clientes!')
    },
    groupReceived (data) {
      data.ticket.attendant = {
        id: data.attendant_id,
        name: data.attendant_name
      }

      if (data.ticket) this.updateRooms(data.ticket, false)
      if (alert) this.playSound()
      this.$buefy.toast.open(`Atenção, você foi adicionado ao grupo ${data.ticket.client_name}!`)
    },
    async fetchMessages ({ room, options = {} }) {
      if (!room.fetchMessages && options.reset) {
        if (this.selectedRoom) this.selectedRoom.fetchMessages = true
        // return
      }

      if (this.loadingChat && options.reset) {
        this.axiosSource.cancel()

        const CancelToken = axios.CancelToken
        this.axiosSource = CancelToken.source()
        this.getMessages({ room, options })

        return
      } else if (!this.axiosSource) {
        const CancelToken = axios.CancelToken
        this.axiosSource = CancelToken.source()
        this.getMessages({ room, options })

        return
      }

      this.getMessages({ room, options })
    },
    async getMessages ({ room, options = {} }) {
      this.loadingChat = true

      if (options.reset) {
        this.messagesPage = 1
        this.selectedRoom = room
        this.setupTicketMenu()
      } else {
        this.messagesPage++
      }
      // possível causa do bug no chat
      // this.applyStyleToMessageContainer(this.selectedRoom)
      this.messagesLoaded = false
      const s = this.rooms.find(ro => ro.roomId === room.roomId)

      if (s) {
        s.unreadCount = 0
      }

      getCancelable(
        `tickets/messages/${this.selectedRoom.roomId}/${this.messagesPage}`,
        this.axiosSource.token
      )
        .then(r => {
          if (options.reset) {
            this.messages = []
          }
          this.messages = [
            ...r.data
              .reverse()
              .map(message =>
                this.adaptMessageToMessage(message, this.getUserId)
              )
              .filter(msg => msg !== undefined),
            ...this.messages
          ]
          if (!r.data.length || r.data.length < 15) {
            this.messagesLoaded = true
          }

          this.loadingChat = false
          this.changingFilter = false

          this.applyStyleToOpenTickets(this.getRooms)
          this.applyStyleToMessageContainer(this.selectedRoom)
        })
        .catch(thrown => {
          if (axios.isCancel(thrown)) {
          } else {
            // handle error
          }

          this.loadingChat = false
          // this.changingFilter = false
        })
    },
    async setTicketAttendant (ticketId) {
      await post(`tickets/setAttendant/${ticketId}`)
    },
    async handleAddContact (contact) {
      this.changingFilter = true
      // contact.ticket_id = this.selectedRoom.roomId
      this.isModalAddContactOpen = false
      let newContact = await this.addContact(contact.info)
      if (newContact.status === 200) {
        this.addContactTags(contact.tags)
        if (this.contactAdding && !this.contactAdding.isContactCard) {
          newContact = newContact.data
          if (newContact.id) {
            this.selectedRoom.contact_id = newContact.id
            this.selectedRoom.contact = newContact
          }
          this.setupTicketMenu()
        }
        this.$buefy.toast.open(
          'O contato foi salvo'
        )
        this.changingFilter = false
      } else {
        this.$buefy.toast.open(
          'Não foi possível salvar o contato'
        )
        this.changingFilter = false
      }
    },
    async handleUpdateContact (contact) {
      try {
        await this.updateContact(contact)
        this.selectedRoom.contact_id = contact.id
        this.selectedRoom.contact = contact
      } catch (error) {
        console.log('Não foi possível alterar as informações do contato')
      }
    },
    async handleDeleteMessage (data) {
      this.closeModal()
      await post(`tickets/delete/${data.messageId}/${data.forEveryone}`, { ww_phone: this.selectedRoom.ww_phone })
    },
    async fetchRooms (order = 'last_message') {
      const ticketsRequest = await get('tickets')
      let tickets = ticketsRequest.data;
      
      if (tickets.length > 30) {
        this.disableDownloadContactsImageDownload = true
        this.$buefy.toast.open(
          'O download de imagens de contatos foi desativado para melhorar a performance. Considere ativar o processo de fechamento automático de tickets para evitar o carregamento de informações desnecessárias no chat. Para mais detalhes, por favor contacte o suporte.'
        )
      }
      
      const promises = tickets.map(room =>
        this.adaptTicketToRoom(room, order)
      )

      this.rooms = await Promise.all(promises)
      this.setLoading(false)

      this.selectedRoom = null
      this.messages = []
    },
    saveFile (blob, filename) {
      if (window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(blob, filename)
      } else {
        const a = document.createElement('a')
        document.body.appendChild(a)
        const url = URL.createObjectURL(blob)
        a.href = url
        a.download = filename
        a.click()
        setTimeout(() => {
          URL.revokeObjectURL(url)
          document.body.removeChild(a)
        }, 0)
      }
    },
    async openFile ({ message, action }) {
      this.changingFilter = true

      try {
        if (message.files[0].url.indexOf('message-details') > -1) {
          let messageDetails = await pureGet(message.files[0].url)
          messageDetails = messageDetails.data

          if (messageDetails.file_base64) {
            message.files[0].url = `data:${messageDetails.file_mimetype};base64,${messageDetails.file_base64}`
            message.files[0].type = messageDetails.file_mimetype
            message.files[0].audio = messageDetails.file_mimetype.indexOf('audio/ogg') > -1

            if (!messageDetails.file_name) {
              let ext
              try {
                ext = message.files[0].type.split('/')[1]
              } catch (error) {
                ext = ''
              }

              message.files[0].name = `download.${ext}`
            } else {
              message.files[0].name = messageDetails.file_name
            }

            if ((message.files[0].name && !isImageFile(message.files[0].name) && !isVideoFile(message.files[0].name) && !isAudioFile(message.files[0].name)) && !message.files[0].audio) {
              const a = document.createElement('a')
              a.href = message.files[0].url
              let ext
              try {
                ext = message.files[0].type.split('/')[1]
              } catch (error) {
                ext = ''
              }
              a.setAttribute('target', '_blank')
              a.download = `${message.files[0].name ?? 'download.' + ext}`
              a.click()
            }
          }
        } else if (message.files[0].url.indexOf('file') > -1) {
          let mediaDetails = await get(`tickets/download-file/${message.id}`)
          mediaDetails = mediaDetails.data

          message.files[0].url = getUrl(`tickets/file/${message.id}`)
          message.file = mediaDetails.file

          if (isVideoFile(mediaDetails.file.mimetype) || isAudioFile(mediaDetails.file.mimetype)) {
            await this.adaptFileMessage(mediaDetails, message)
          } else if (isImageFile(mediaDetails.file.mimetype)) {
            this.changingFilter = false
            message.files[0].url = getUrl(`tickets/file/${message.id}`)
            message.files[0].type = mediaDetails.file.mimetype
            this.downloadFile(message)
          } else {
            this.changingFilter = false
            this.downloadFile(message)
          }
        } else {
          const messageDetails = await pureBlobGet(message.files[0].url)

          if (messageDetails.data) {
            const binaryData = [messageDetails.data]
            this.saveFile(new Blob(binaryData, { type: message.files[0].type }), message.files[0].name)
          }
        }
      } catch (error) {
        console.log(error)
        this.$buefy.toast.open(
          'Não foi possível baixar o arquivo!'
        )
        this.changingFilter = false
      }
      this.changingFilter = false
    },
    async sendSurvey (
      roomId,
      ticket_reason_id
    ) {
      await post(`satisfaction-surveys/send/${roomId}`, { ticket_reason_id })
    },
    async closeTicket ({
      roomId,
      should_notify_client,
      ticket_reason_id,
      ticket_reason_explanation,
      ticket_close_message,
      closed_at
    }) {
      await post(`tickets/close/${roomId}`, {
        should_notify_client,
        ticket_reason_id,
        ticket_reason_explanation,
        ticket_close_message,
        closed_at
      })
    },
    closeTicketHandler (data) {
      this.closeTicket({
        roomId: data.ticket.id,
        ...data
      })
        .then(() => {
          this.closeModal()
          this.selectedRoom = null
          this.messages = []
          this.fetchRooms()

          this.sendSurvey(data.ticket.id, data.ticket_reason_id)
        })
        .catch(err => {
          this.$buefy.dialog.alert({
            title: 'Erro no fechamento do ticket',
            message: err.response.data.message,
            type: 'is-danger',
            hasIcon: true,
            icon: 'times-circle',
            iconPack: 'fa',
            ariaRole: 'alertdialog',
            ariaModal: true
          })
        })
    },
    async openCloseTicketHandler () {
      if (!this.selectedRoom) {
        this.$buefy.toast.open(
          'É necessário selecionar um ticket para fecha-lo!'
        )
        return
      }
      if (this.selectedRoom.is_group && this.selectedRoom.attendant_id !== '' && this.selectedRoom.attendant_id) {
        if (this.selectedRoom.attendant_id !== this.getUserId) {
          this.$buefy.toast.open(
            'Somente o responsável pelo grupo pode fechá-lo'
          )
          return
        }
      }
      await this.fetchEnabledReasons(this.selectedRoom.ww_phone)
      await this.fetchSettings()
      this.settings = [...this.allSettingsEnabled]
      this.isModalCloseTicketOpen = true
    },
    async openSendTemplateHandler () {
      if (!this.selectedRoom) {
        this.$buefy.toast.open(
          'É necessário selecionar um ticket para enviar um template!'
        )
        return
      }
      if (this.selectedRoom.is_group && this.selectedRoom.attendant_id !== '' && this.selectedRoom.attendant_id) {
        if (this.selectedRoom.is_group) {
          this.$buefy.toast.open(
            'Não é possível enviar um template para um grupo de whatsapp'
          )
          return
        }
        if (this.selectedRoom.attendant_id !== this.getUserId) {
          this.$buefy.toast.open(
            'Somente o responsável pelo grupo pode fechá-lo'
          )
          return
        }
        if (this.selectedRoom.setting.api_type !== API_TYPE.OFICIAL) {
          this.$buefy.toast.open(
            'Não é possível enviar templates para esse ticket'
          )
          return
        }
      }
      await this.fetchTemplates()
      this.isModalSendTemplateOpen = true
    },
    async handleSendTemplate (result) {
      this.isModalSendTemplateOpen = false
      this.changingFilter = true
      try {
        const ticket = await post(`tickets/send-template/${this.selectedRoom.id}`, {
          result
        }).then(r => r.data)
        this.selectedRoom.expiration_date = ticket.expiration_date
      } catch (error) {
        this.$buefy.dialog.alert({
          title: 'Erro no envio do template',
          message: error.response.data.message,
          type: 'is-danger',
          hasIcon: true,
          icon: 'times-circle',
          iconPack: 'fa',
          ariaRole: 'alertdialog',
          ariaModal: true
        })
      }
      this.changingFilter = false
      this.applyStyleToMessageContainer(this.selectedRoom)
    },
    async menuActionHandler ({ roomId, action }) {
      switch (action.name) {
        case 'closeTicket':
          this.openCloseTicketHandler(roomId)
          break
        case 'transferTicket':
          if (this.selectedRoom && !this.selectedRoom.attendant_id) {
            this.$buefy.toast.open(
              'Não é possível transferir um atendimento que não tenha atendente!'
            )
            return
          }
          if (!this.selectedRoom) {
            this.$buefy.toast.open(
              'É necessário selecionar um atendimento para se realizar a transferência!'
            )
            return
          }
          if (this.selectedRoom.is_group) {
            this.$buefy.toast.open(
              'Não é possível transferir um grupo de whatsapp'
            )
            return
          }
          await this.fetchAttendants()
          await this.fetchDepartments(this.selectedRoom.ww_phone)
          this.isModalTransferTicketOpen = true
          break
        case 'showTicketDetails':
          await this.fetchEnabledTags()
          if (this.selectedRoom.contact) {
            await this.fetchContactTags(this.selectedRoom.contact.id)
          }
          this.isModalTicketDetailsOpen = true
          break
        case 'addContact':
          if (!this.selectedRoom) return
          this.contactAdding = {
            name: this.selectedRoom.client_name,
            phone: this.selectedRoom.client_phone
          }
          this.isModalAddContactOpen = true
          break
        case 'defaultMessages':
          this.fetchDefaultMessagesWithoutFile().then(() => {
            this.isModalDefaultMessagesOpen = true
          })
          break
        case 'searchMessage':
          this.isSearchRoomMessagesOpen = true
          break
        case 'sendTemplate':
          this.openSendTemplateHandler()
          break
        case 'setContactAttendant':
          if (this.selectedRoom.attendant_id !== this.getUserId) {
            this.$buefy.toast.open(
              'Somente é possível encarteirar a partir de um atendimento que você esteja atendendo!'
            )
            return
          }

          this.isModalSetContactAttendantOpen = true
          break
      }
    },
    async handleTicketOpenedManually (ticket) {
      const newRoom = await this.adaptTicketToRoom(ticket)

      if (this.selectedRoom && this.selectedRoom.id !== newRoom.id) {
        this.selectedRoom.fetchMessages = false
      }

      this.rooms.push(newRoom)

      this.applyStyleToOpenTickets(this.getRooms)

      this.$buefy.toast.open(
        `O atendimento ${ticket.id} foi aberto manualmente`
      )
    },
    handleTicketClosed (ticket) {
      if (this.removeTicketFromRoomsList(ticket)) {
        this.$buefy.toast.open(`O atendimento ${ticket.id} foi fechado`)
      }
    },
    handleTicketAttendantSelected (ticket) {
      ticket.id = ticket.ticket_id

      const is_group = !(typeof ticket.isAMember === 'undefined')
      const is_member = ticket.isAMember

      if ((this.getIsSupervisor && !is_group) || (this.getIsSupervisor && is_group && is_member)) {
        if (
          this.selectedRoom &&
          this.selectedRoom.roomId === ticket.ticket_id
        ) {
          this.selectedRoom.attendant_id = ticket.attendant.id
          this.selectedRoom.attendant = ticket.attendant
          this.selectedRoom.lastMessage = {
            content: this.getTicketContent(ticket)
          }

          this.applyStyleToOpenTickets([this.selectedRoom])
          this.applyStyleToSelectAttendantButton(this.selectedRoom)
          this.applyStyleToMessageContainer(this.selectedRoom)
        } else {
          if (this.selectedRoom) this.selectedRoom.fetchMessages = false

          const ticketRoom = this.rooms.find(
            tck => tck.roomId === ticket.ticket_id
          )
          ticketRoom.attendant_id = ticket.attendant.id
          ticketRoom.attendant = ticket.attendant
          ticketRoom.lastMessage = { content: this.getTicketContent(ticket) }
          this.applyStyleToOpenTickets([ticketRoom])
        }

        if (this.getUserId !== ticket.attendant.id && is_group) {
          this.$buefy.toast.open('Você foi adicionado a um grupo')
        }
        if (!is_group) {
          this.$buefy.toast.open(
            `O atendimento ${ticket.ticket_id} foi selecionado pelo atendente ${ticket.attendant.name}`
          )
        }
      } else {
        const ticketIndex = this.rooms.findIndex(
          room => room.roomId === ticket.ticket_id
        )
        const ticketRemove = this.rooms.find(
          room => room.roomId === ticket.ticket_id
        )

        if (ticketIndex < 0) return

        if (
          this.selectedRoom &&
          this.selectedRoom.roomId === ticketRemove.roomId
        ) {
          // removing modifying the reference to force refresh room list and selected room messages
          this.rooms = this.rooms.filter(
            room => room.roomId !== ticket.ticket_id
          )
          this.selectedRoom = null
        } else {
          this.rooms.splice(ticketIndex, 1)
        }
      }
    },
    handleRemovedFromGroup (ticket) {
      if (this.removeTicketFromRoomsList(ticket)) {
        this.$buefy.toast.open(`Você foi removido do grupo ${ticket.client_name}`)
      }
    },
    async handleSearchMessage (msg) {
      this.searchingMessages = true
      const messages = await get(`tickets/search-messages/${this.selectedRoom.roomId}/${msg}`)
      this.foundMessages = messages.data.map(el => {
        el.timestamp = `${this.formatDate(el.whatsapp_timestamp ?? el.created_at)} - ${this.formatTime(el.whatsapp_timestamp ?? el.created_at)}`
        return el
      })
      this.searchingMessages = false
    },
    closeMessageSearch () {
      this.isSearchRoomMessagesOpen = false
      this.foundMessages = []
    },
    async getMessagesFromSearch (msgId) {
      if (this.messages.filter(el => el.id === msgId).length > 0) {
        this.scrollToMessage(msgId)
      } else {
        try {
          this.messagesLoaded = false
          this.loadingChat = true

          const msg = await get(`tickets/messages/${this.selectedRoom.roomId}/${this.messagesPage++}`, { msgId })
          if (msg.data.filter(el => el.id === msgId).length > 0) {
            this.messages = [
              ...msg.data
                .reverse()
                .map(message => this.adaptMessageToMessage(message, this.getUserId))
                .filter(msg => msg !== undefined),
              ...this.messages
            ]

            this.messagesPage = Math.floor(this.messages.length / 15)

            this.applyStyleToOpenTickets(this.getRooms)
            this.applyStyleToMessageContainer(this.selectedRoom)
          }
        } catch (error) {
          console.log('error', error)
        } finally {
          this.loadingChat = false
          this.changingFilter = false

          nextTick(() => this.scrollToMessage(msgId))
        }
      }
    },
    scrollToMessage (msgId) {
      const element = document.getElementById(msgId)
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' })
        element.classList.add('highlight-message')
        setTimeout(() => {
          element.classList.remove('highlight-message')
        }, 1000)
      }
    },
    getRoomName (ticket) {
      if (ticket.contact) {
        return ticket.contact.name
      }
      if (ticket.client_name) {
        return ticket.client_name
      }

      return ticket.client_phone
    },
    applyStyleToOpenTickets (tickets) {
      tickets.forEach(ticket => {
        if (!ticket.attendant_id) {
          setTimeout(() => {
            const element = document.getElementById(ticket.id ?? ticket.roomId)
            if (!element) return
            element.classList.add('vac-room-item-open')
          }, 500)
        } else if (ticket.attendant_id !== this.getUserId) {
          setTimeout(() => {
            const element = document.getElementById(ticket.id ?? ticket.roomId)
            if (!element) return
            element.classList.add('vac-room-item-supervisor')
          }, 500)
        } else {
          const element = document.getElementById(ticket.id ?? ticket.roomId)
          if (!element) return
          element.classList.remove('vac-room-item-open')
          element.classList.remove('vac-room-item-supervisor')
        }
      })
    },
    async selectAttendant () {
      if (!this.selectedRoom.attendant_id) {
        const ticket = await get(`tickets/${this.selectedRoom.roomId}`)
        if (ticket.data.is_open === false) {
          this.$buefy.dialog.alert({
            title: 'Ticket Fechado',
            message:
              'Não foi possível selecionar o ticket pois o cliente finalizou o atendimento enquanto esperava pelo departamento. Caso precise entrar em contato, criar um ticket manualmente para este cliente.',
            type: 'is-danger',
            hasIcon: true,
            icon: 'times-circle',
            iconPack: 'fa',
            ariaRole: 'alertdialog',
            ariaModal: true
          })
          await this.fetchRooms()
          return
        } else {
          try {
            if (this.getStatus.key === 'invisible') {
              this.$buefy.toast.open(
                'Você está ausente! Não pode selecionar novos tickets'
              )
              return
            }
            if (this.selectingAttendant) return
            this.loadingChat = true
            this.selectingAttendant = true
            await this.setTicketAttendant(this.selectedRoom.roomId)
            this.selectedRoom.attendant_id = this.getUserId
            this.selectedRoom.attendant = {
              id: this.getUserId,
              name: this.getName
            }
            this.selectedRoom.lastMessage = {
              content: this.getTicketContent(this.selectedRoom)
            }
            // const element = document.getElementById(room.roomId)
            // element.classList.remove('vac-room-item-open')
          } catch (error) {
            console.error(
              'Error during the selection attendant procedure.',
              error
            )
            this.$buefy.dialog.alert({
              title: 'Não foi possível selecionar o ticket',
              message: 'O ticket já pode ter sido fechado ou selecionado por outro atendente!',
              type: 'is-danger',
              hasIcon: true,
              icon: 'times-circle',
              iconPack: 'fa',
              ariaRole: 'alertdialog',
              ariaModal: true
            })
            this.fetchRooms()
            return
          } finally {
            this.loadingChat = false
            this.selectingAttendant = false
          }
        }
      }
      this.applyStyleToSelectAttendantButton(this.selectedRoom)
      this.applyStyleToMessageContainer(this.selectedRoom)
    },
    removeTicketFromRoomsList (ticket) {
      const ticketIndex = this.rooms.findIndex(
        room => room.roomId === ticket.id
      )
      const ticketRemove = this.rooms.find(room => room.roomId === ticket.id)

      if (ticketIndex < 0) return false
      if (
        this.selectedRoom &&
        this.selectedRoom.roomId === ticketRemove.roomId
      ) {
        // removing modifying the reference to force refresh room list and selected room messages
        // const roomId = this.rooms.indexOf((room) => room.roomId !== ticket.id)
        // this.rooms.splice(roomId, 1)
        this.rooms = this.rooms.filter(room => room.roomId !== ticket.id)
        this.selectedRoom = null
        this.messages = []
      } else {
        if (this.selectedRoom) this.selectedRoom.fetchMessages = false
        const roomId = this.rooms.map(room => room.roomId).indexOf(ticket.id)
        if (roomId < 0) return false
        this.rooms.splice(roomId, 1)
      }

      return true
    },
    removeTicketTransfered (data) {
      const ticket = data.ticket
      if (this.removeTicketFromRoomsList(ticket)) {
        this.$buefy.toast.open(`O atendimento ${ticket.id} foi transferido`)
      }
    },
    insertTicketTransfered (data) {
      if (data.ticket) this.updateRooms(data.ticket, false)
      if (alert) this.playSound()
      if (data.ticket.department) {
        this.$buefy.toast.open(
          `Um novo ticket foi transferido para o departamento ${data.ticket.department.name}`
        )
      } else if (data.attendant_from) {
        this.$buefy.toast.open(
          'Um novo ticket foi transferido para um usuário do seu departamento'
        )
      } else {
        this.$buefy.toast.open(
          'Um novo ticket foi transferido para o seu departamento'
        )
      }
    },
    updateTicketTransfered (data) {
      if (data.ticket) this.updateRooms(data.ticket, false)
    },
    createSelectTicketElement () {
      this.selectAttentantElement = document.createElement('div')
      this.selectAttentantElement.id = 'select-attendant-button'
      this.selectAttentantElement.classList.add('vac-box-footer__hidden')

      this.sendTemplateElement = document.createElement('div')
      this.sendTemplateElement.id = 'send-template-button'
      this.sendTemplateElement.classList.add('vac-box-footer__hidden')

      this.blockedMessageElement = document.createElement('div')
      this.blockedMessageElement.id = 'blocked-message'
      this.blockedMessageElement.classList.add('vac-box-footer__hidden')

      const selectAttendantButton = document.createElement('b-button')
      selectAttendantButton.textContent = 'Selecionar ticket'
      selectAttendantButton.classList.add('button')
      selectAttendantButton.classList.add('is-success')
      selectAttendantButton.classList.add('m-2')
      selectAttendantButton.classList.add('is-pulled-right')
      selectAttendantButton.classList.add('is-fullwidth')
      selectAttendantButton.onclick = this.selectAttendant

      const closeTicketButton = document.createElement('b-button')
      closeTicketButton.textContent = 'Fechar ticket'
      closeTicketButton.classList.add('button')
      closeTicketButton.classList.add('is-danger')
      closeTicketButton.classList.add('is-outlined')
      closeTicketButton.classList.add('m-2')
      closeTicketButton.classList.add('is-pulled-right')
      closeTicketButton.classList.add('is-fullwidth')
      closeTicketButton.onclick = () =>
        this.openCloseTicketHandler(
          this.selectedRoom ? this.selectedRoom.roomId : null
        )
      const sendTemplateButton = document.createElement('b-button')
      sendTemplateButton.textContent = 'Enviar mensagem'
      sendTemplateButton.classList.add('button')
      sendTemplateButton.classList.add('is-info')
      sendTemplateButton.classList.add('is-fullwidth')
      sendTemplateButton.onclick = () => this.openSendTemplateHandler(this.selectedRoom ? this.selectedRoom.roomId : null)

      const blockedMessageButton = document.createElement('b-button')
      blockedMessageButton.textContent = 'Aguardando uma resposta do contato'
      blockedMessageButton.classList.add('button')
      blockedMessageButton.classList.add('is-danger')
      blockedMessageButton.classList.add('is-outlined')
      blockedMessageButton.classList.add('is-fullwidth')
      blockedMessageButton.disabled = true
      blockedMessageButton.addEventListener('mouseover', function () {
        this.textContent = 'Fechar Ticket'
      })
      blockedMessageButton.addEventListener('mouseout', function () {
        this.textContent = 'Aguardando uma resposta do contato'
      })
      blockedMessageButton.onclick = () =>
        this.openCloseTicketHandler(
          this.selectedRoom ? this.selectedRoom.roomId : null
        )

      this.blockedMessageElement.classList.add('blocked-message-div')
      this.blockedMessageElement.classList.add('vac-box-footer')
      this.blockedMessageElement.appendChild(blockedMessageButton)

      this.selectAttentantElement.classList.add('select-attendant-div')
      this.selectAttentantElement.classList.add('vac-box-footer')
      this.selectAttentantElement.appendChild(closeTicketButton)
      this.selectAttentantElement.appendChild(selectAttendantButton)
      this.sendTemplateElement.classList.add('send-template-div')
      this.sendTemplateElement.classList.add('vac-box-footer')
      this.sendTemplateElement.appendChild(sendTemplateButton)

      let elements = document.getElementsByClassName('vac-room-footer')
      elements[0].appendChild(this.selectAttentantElement)
      elements[0].appendChild(this.sendTemplateElement)
      elements[0].appendChild(this.blockedMessageElement)

      elements = document.getElementsByClassName('vac-icon-textarea-left')
      // if (!elements[0]) return
      // const element = elements[0]
      // const chatDropdown = document.getElementById('chatDropdownMenu')
      // chatDropdown.style.display = 'initial'
      // element.appendChild(chatDropdown)
    },
    applyStyleToMessageContainer (selectedTicket) {
      if (!selectedTicket) return

      let elements = document.getElementsByClassName('vac-container-scroll')
      if (!elements[0]) return
      let element = elements[0]

      elements = document.getElementsByClassName('vac-box-footer')
      if (!elements[0]) return
      element = elements[0]

      if (this.loadingChat) {
        for (const el of elements) {
          el.classList.add('vac-box-footer__hidden')
        }
        this.applyStyleToSelectAttendantButton(selectedTicket)
        return
      }

      if (selectedTicket.attendant_id) {
        const settings = this.allSettingsEnabled.find(el => el.phone === selectedTicket.ww_phone)
        const isOficial = settings ? settings.api_type === API_TYPE.OFICIAL : false
        if (isOficial) {
          const lastMessageSent = new Date(selectedTicket.expiration_date)
          if (new Date() > lastMessageSent) {
            element.classList.add('vac-box-footer__hidden')
            this.applyStyleToSendTemplateButton(selectedTicket)
          } else {
            if (selectedTicket.send_message_blocked) {
              element.classList.add('vac-box-footer__hidden')
              this.applyStyleToBlockedMessageButton(selectedTicket)
            } else {
              element.classList.remove('vac-box-footer__hidden')
            }
          }
        } else {
          element.classList.remove('vac-box-footer__hidden')
        }
      } else {
        element.classList.add('vac-box-footer__hidden')
      }

      this.applyStyleToSelectAttendantButton(selectedTicket)
      this.applyStyleToSendTemplateButton(selectedTicket)
      this.applyStyleToBlockedMessageButton(selectedTicket)
    },
    applyStyleToSelectAttendantButton (ticket) {
      const element = document.getElementById('select-attendant-button')
      if (!element) return

      if (this.loadingChat) {
        element.classList.add('vac-box-footer__hidden')
        return
      }

      if (!ticket.attendant_id) {
        element.classList.remove('vac-box-footer__hidden')
      } else {
        element.classList.add('vac-box-footer__hidden')
      }
    },
    applyStyleToSendTemplateButton (ticket) {
      const element = document.getElementById('send-template-button')
      if (!element) return

      if (this.loadingChat) {
        element.classList.add('vac-box-footer__hidden')
        return
      }
      const lastMessageSent = new Date(ticket.expiration_date)
      const isOficial = ticket.setting.api_type === API_TYPE.OFICIAL || this.allSettingsEnabled.find(el => el.phone === ticket.ww_phone)?.api_type === API_TYPE.OFICIAL
      if (new Date() > lastMessageSent && isOficial && ticket.attendant_id && ticket.department_id) {
        element.classList.remove('vac-box-footer__hidden')
      } else {
        element.classList.add('vac-box-footer__hidden')
      }
    },
    applyStyleToBlockedMessageButton (ticket) {
      const element = document.getElementById('blocked-message')
      if (!element) return

      if (this.loadingChat) {
        element.classList.add('vac-box-footer__hidden')
        return
      }
      const lastMessageSent = new Date(ticket.expiration_date)
      const isOficial = ticket.setting.api_type === API_TYPE.OFICIAL || this.allSettingsEnabled.find(el => el.phone === ticket.ww_phone)?.api_type === API_TYPE.OFICIAL
      if (ticket.send_message_blocked && isOficial && new Date() < lastMessageSent && ticket.attendant_id && ticket.department_id) {
        element.classList.remove('vac-box-footer__hidden')
      } else {
        element.classList.add('vac-box-footer__hidden')
      }
    },
    applyStyleToNoteMessages () {
      const noteMessages = this.messages.filter(el => el.is_note)
      const msgIds = noteMessages.map(el => el.id)
      for (const id of msgIds) {
        const element = document.getElementById(id.toString())
        if (element) {
          element.classList.add('transfer-note')
        }
      }
    },
    getTicketContent (ticket) {
      if (ticket.setting && ticket.setting.color) {
        const phoneColor = `
          <span style="
            border-radius: 50%;
            background-color: ${ticket.setting.color};
            width: 10px;
            height: 10px;
            display: inline-block"
          ></span> 
        `
        return (
          phoneColor +
          `${ticket.id} | ${ticket.department ? ticket.department.name : ''} | ${ticket.attendant ? ticket.attendant.name : ''
          }`
        )
      }
      return (
        `${ticket.id} | ${ticket.department ? ticket.department.name : ''} | ${ticket.attendant ? ticket.attendant.name : ''
        }`
      )
    },
    async adaptTicketToRoom (ticket, order) {
      this.applyStyleToOpenTickets([ticket])
      let index

      if (ticket.ww_phone) {
        ticket.tags = [{
          content: `Origem: ${ticket.ww_phone}`,
          color: 'blue'
        }]
      }

      if (ticket.expiration_date) {
        ticket.tags = [...ticket.tags, {
          content: `\n\nExpira em: ${this.formatDateTime(ticket.expiration_date)}`,
          color: 'red'
        }]
      }

      const lastMessage = ticket.last_message ?? ticket.created_at

      switch (order) {
        case 'created_at':
          index = ticket.created_at
          break
        case 'last_message':
          index = lastMessage
          break
        case 'room_name':
          index = this.getRoomName(ticket).toLowerCase()
          break
        case 'pending':
          index = ticket.unread_count
          break
        default:
          index = lastMessage
          break
      }

      const adaptedTicket = {
        ...ticket,
        roomId: ticket.id,
        roomName: this.getRoomName(ticket),
        unreadCount: ticket.unread_count,
        index: index,
        lastMessage: {
          content: this.getTicketContent(ticket),
          timestamp: this.datesAreOnSameDay(new Date(Date.now()), new Date(lastMessage)) ? `${this.formatTime(lastMessage)}` : `${this.formatDate(lastMessage)}`,
          date: ticket.created_at
        },
        users: [
          {
            _id: this.getUserId,
            username: 'John Doe'
          },
          {
            _id: ticket.client_phone,
            username: 'John Snow'
          },
          {
            _id: '2',
            username: 'John Snow'
          }
        ],
        typingUsers: [],
        attendant_id: ticket.attendant_id,
        fetchMessages: true
      }

      if (!this.disableDownloadContactsImageDownload) {
        adaptedTicket.avatar = getUrl(`tickets/image/${ticket.id}`)
      }

      return adaptedTicket
    },
    setLoading (isLoading) {
      this.loading.rooms = isLoading
      this.loaded.rooms = !isLoading
    },
    closeModalDragDrop () {
      this.isModalTransferTicketOpen = false
      this.isModalCreateTicketOpen = false
      this.isModalSendContactOpen = false
      this.isModalTicketDetailsOpen = false
      this.isModalCloseTicketOpen = false
      this.isModalDefaultMessagesOpen = false
    },
    closeModal () {
      this.isModalTransferTicketOpen = false
      this.isModalCreateTicketOpen = false
      this.isModalSendContactOpen = false
      this.isModalTicketDetailsOpen = false
      this.isModalCloseTicketOpen = false
      this.isModalDefaultMessagesOpen = false
      this.isModalAddContactOpen = false
      this.isModalDeleteMessageOpen = false
      this.isModalForwardMessageOpen = false
      this.isModalContactCardsOpen = false
      this.isModalSendTemplateOpen = false
      this.isModalSetContactAttendantOpen = false
      this.isModalForwardMessageFromInternalChatOpen = false
    },
    setupTicketMenu () {
      this.menuActions = this.selectedRoom && this.selectedRoom?.contact_id && !this.selectedTicket?.is_group ? CONTACT_MENU_ACTIONS : NON_CONTACT_MENU_ACTIONS
    },
    changeStatusFilter (newValue) {
      this.changingFilter = true
      this.setLoading(true)
      this.messagesPage = 1
      this.messages = []
      this.selectedRoom = null
      this.statusFilter = newValue
      const rooms = this.getRooms

      if (rooms.length > 0) {
        // if (this.axiosSource) {
        //   this.axiosSource.cancel()
        //   this.axiosSource = null
        // }
        // this.selectedRoom = rooms[0]
        this.fetchMessages({ room: rooms[0], options: { reset: true } })
      } else {
        this.changingFilter = false
      }
      this.setLoading(false)
    },
    getFilterClass (option) {
      if (option === this.statusFilter) return 'is-active'

      return ''
    },
    getRoomCounts (status) {
      switch (status) {
        case 'ALL':
          return this.rooms.length
        case 'ATT':
          return this.rooms.filter(
            room => !!room.attendant_id && room.attendant_id !== this.getUserId
          ).length
        case 'MYT':
          return this.rooms.filter(room => room.attendant_id === this.getUserId)
            .length
        case 'PEN':
          return this.rooms.filter(room => !room.attendant_id).length
        case 'OGP':
          return this.rooms.filter(room => room.is_group === true).length
        case 'TIC':
          return this.rooms.filter(room => room.is_group === false).length
        default:
          return this.rooms.length
      }
    },
    async createTicketButtonHandler () {
      await this.fetchSettings()
      this.isModalCreateTicketOpen = true
    },
    async sendContactHandler () {
      this.isModalSendContactOpen = true
    },
    async addContactButtonHandler (contact) {
      if (!contact) {
        this.fetchEnabledTags()
        this.contactAdding = null
        this.isModalAddContactOpen = true
      } else {
        if (Array.isArray(contact)) {
          this.contactCards = contact
          this.isModalContactCardsOpen = true
        } else {
          this.contactAdding = {
            name: contact.name,
            phone: contact.phone,
            isContactCard: true
          }
          this.closeModal()
          this.searchForContact(this.contactAdding)
        }
      }
    },
    async searchForContact (contact) {
      const duplicateContact = await get(`contacts/findContact/${contact.phone}/null`)
      if (duplicateContact.data) {
        this.confirmDuplicateContact(duplicateContact.data, contact)
      } else {
        this.fetchEnabledTags()
        this.isModalAddContactOpen = true
      }
    },
    confirmDuplicateContact (contact, newContact) {
      this.$buefy.dialog.confirm({
        title: 'Contato já existente',
        message: `Já existe um contato com esse número!<br><br>Contato existente:<br>${contact.name} - ${contact.phone}<br><br>Contato a ser salvo:<br>${newContact.name} - ${newContact.phone}<br><br>Deseja <b>criar</b> mesmo assim?`,
        confirmText: 'Criar contato',
        type: 'is-danger',
        size: 'is-big',
        hasIcon: true,
        cancelText: 'Cancelar',
        onConfirm: () => {
          this.fetchEnabledTags()
          this.isModalAddContactOpen = true
        }
      })
    },
    socketReconnect () {
      if (this.selectedRoom && this.messages.length > 0) {
        this.$socket.emit('fetchChats', {
          ticketId: this.selectedRoom.id,
          messageId: this.messages[this.messages.length - 1].id,
          attendantId: this.getUserId,
          unreadTickets: this.rooms.filter(r => r.unreadCount > 0).map(r => ({ id: r.id, unread_count: r.unreadCount }))
        })
      }
    },
    updateWaitingMessage (newMessage) {
      this.messages = this.messages.map(message => {
        if (message.whatsapp_message_id === newMessage.whatsapp_message_id) {
          const newMsg = JSON.parse(JSON.stringify(message))

          newMsg.message = newMessage.message
          newMsg.deleted = newMessage.deleted
          newMsg.waiting = false
          newMsg.message_type = newMessage.message_type
          newMsg.file_base64 = newMessage.file_base64
          newMsg.file_mimetype = newMessage.file_mimetype
          newMsg.file_name = newMessage.file_name
          newMsg.whatsapp_quoted_message_id = newMessage.whatsapp_quoted_message_id

          return this.adaptMessageToMessage(newMsg, this.getUserId, true)
        }

        return message
      })
    },
    notifySendingFail (data) {
      this.$buefy.snackbar.open({
        message: `Ocorreu um <b>erro ao enviar a mensagem</b> no <b>ticket ${data.ticket_id}</b>`,
        type: 'is-danger',
        position: 'is-bottom-right',
        pauseOnHover: true,
        queue: false,
        duration: 1000000
      })
    },
    forwardMessageFromInternalChat (message) {
      this.isModalForwardMessageFromInternalChatOpen = true
      this.forwardingMessage = message
    },
    async sendMessageFromInternalChat (data) {
      const { contact, department, ww_phone } = data
      if (this.forwardingMessage.isDeleted) {
        this.$buefy.toast.open(
          'Não é possível encaminhar uma mensagem já excluida!'
        )
        this.closeModal()
        return
      }

      let ticket = this.rooms.filter(r => {
        const contact_id = r.contact ? r.contact.id : r.contact_id
        const department_id = r.department ? r.department.id : r.department_id
        return r.ww_phone === ww_phone && contact_id === contact.id && department_id === department.id
      })[0]
      if (!ticket) {
        try {
          this.setLoading(true)
          ticket = await this.createNewTicket({
            client_phone: contact.phone,
            client_name: contact.name,
            phone_from: ww_phone,
            department_id: department.id || department.department_id
          })

          await this.fetchRooms()

          this.selectedRoom = this.rooms.find(
            ro => ro.roomId === ticket.id
          )
          this.setupTicketMenu()
        } catch (error) {
          this.$buefy.dialog.confirm({
            title: 'Erro ao encaminhar mensagem',
            message: `${error.message}`,
            type: 'is-danger',
            hasIcon: true,
            cancelText: 'Fechar'
          })
        } finally {
          this.setLoading(false)
        }
      }

      if (ticket) {
        await post('messages/user_message', {
          message: this.forwardingMessage.content,
          ticket_id: ticket.id,
          file_id: this.forwardingMessage.file_id,
          reply_message_id: null
        })
        this.closeModal()
      }
    }
  },
  beforeDestroy () {
    clearInterval(this.tokenCheckInterval)

    this.$socket.removeAllListeners('client_message')
    this.$socket.removeAllListeners('user_message_sent')
    this.$socket.removeAllListeners('ticket_transfered')
    this.$socket.removeAllListeners('ticket_transfered_insert')
    this.$socket.removeAllListeners('ticket_transfered_remove')
    this.$socket.removeAllListeners('ticket_transfered_update')
    this.$socket.removeAllListeners('message_status_changed')
    this.$socket.removeAllListeners('message_progress_changed')
    this.$socket.removeAllListeners('ticket_closed')
    this.$socket.removeAllListeners('ticket_opened_manually')
    this.$socket.removeAllListeners('ticket_attendent_selected')
    this.$socket.removeAllListeners('ticket_received')
    this.$socket.removeAllListeners('message_deleted')
    this.$socket.removeAllListeners('added_to_group')
    this.$socket.removeAllListeners('removed_from_group')
    this.$socket.removeAllListeners('connection')
    this.$socket.removeAllListeners('update_message')
    this.$socket.removeAllListeners('send_message_fail')
    this.$socket.removeAllListeners('message_waiting_ticket')

    this.$socket.emit('chat_closed')
  },
  watch: {
    selectedRoom (newValue) {
      if (newValue && this.allSettingsEnabled.find(el => el.phone === newValue.ww_phone)?.api_type === API_TYPE.OFICIAL || newValue?.setting?.api_type === API_TYPE.OFICIAL) {
        this.messageActions = CLOUD_API_MESSAGE_ACTIONS
        this.acceptedFiles = OFICIAL_ACCEPTED_FILES
      } else {
        this.messageActions = DEFAULT_MESSAGE_ACTIONS
        this.acceptedFiles = '*'
      }

      this.foundMessages = []
      this.isSearchRoomMessagesOpen = false
      this.searchingMessages = false
    }
  },
  mounted () {
    const CancelToken = axios.CancelToken
    this.axiosSource = CancelToken.source()

    this.createSelectTicketElement()
    this.setLoading(true)

    this.$socket.connect()

    this.$socket.emit('open_chat')

    this.$socket.on('client_message', msg => {
      this.messageReceived(msg, true)
    })

    this.$socket.on('user_message_sent', msg => {
      this.messageReceived(msg)
    })

    this.$socket.on('ticket_transfered', data => {
      this.ticketTransfered(data)
    })

    this.$socket.on('ticket_transfered_insert', data => {
      setTimeout(() => {
        this.insertTicketTransfered(data)
      }, 500)
    })

    this.$socket.on('ticket_transfered_remove', data => {
      this.removeTicketTransfered(data)
    })

    this.$socket.on('ticket_transfered_update', data => {
      setTimeout(() => {
        this.updateTicketTransfered(data)
      }, 500)
    })

    this.$socket.on('message_status_changed', data => {
      console.warn(data)
      this.messageStatusChanged(data)
    })

    this.$socket.on('message_progress_changed', data => {
      this.messageProgressChanged(data)
    })

    this.$socket.on('ticket_closed', ticket => {
      console.warn('handleTicketClosed', ticket)
      this.handleTicketClosed(ticket)
    })

    this.$socket.on('ticket_opened_manually', ticket => {
      this.handleTicketOpenedManually(ticket)
    })

    this.$socket.on('ticket_attendent_selected', ticket => {
      this.handleTicketAttendantSelected(ticket)
    })

    this.$socket.on('ticket_received', ticket => {
      this.ticketReceived(ticket)
    })

    this.$socket.on('message_deleted', msg => {
      this.messageDeleted(msg)
    })

    this.$socket.on('added_to_group', data => {
      this.groupReceived(data)
    })

    this.$socket.on('removed_from_group', ticket => {
      this.handleRemovedFromGroup(ticket)
    })

    this.$socket.on('connection', msg => {
      // settar horário de conexão
      this.socketReconnect()
    })

    this.$socket.on('update_message', msg => {
      this.updateWaitingMessage(msg)
    })

    this.$socket.on('send_message_fail', data => {
      this.notifySendingFail(data)
    })

    this.$socket.on('message_waiting_ticket', data => {
      this.$buefy.snackbar.open({
        message: `A mensagem agendada ${data.message.id} está aguardando o ticket ${data.ticket.id} ser fechado para ser enviada.`,
        type: 'is-warning',
        position: 'is-bottom-right',
        pauseOnHover: true,
        queue: true,
        duration: 1000000
      })
    })

    this.fetchRooms()

    // Verificar token a cada 5 minutos
    this.tokenCheckInterval = setInterval(this.checkToken(), 300000)
  },
  updated () {
    if (this.messages.some(el => el.is_note === true)) {
      this.applyStyleToNoteMessages()
    }
  }
}
</script>

<style lang="scss">
.chat-window {
  padding: 10px;
  // margin-bottom: 10px;
}

.vac-room-item-open {
  border-color: aquamarine;
  border-width: 2px;
  border-style: solid;
}

.vac-room-item-supervisor {
  border-color: rgb(215, 232, 185);
  border-width: 2px;
  border-style: solid;
  // animation: blinker 1.5s linear infinite;
}

.vac-container-scroll__margin-bottom {
  margin-bottom: 60px;
}

.vac-box-footer__hidden {
  display: none !important;
}

.select-attendant-div {
  background-color: white;
}

@keyframes blinker {
  50% {
    opacity: 30%;
  }
}

.button-icon {
  cursor: pointer;
  color: var(--chat-icon-color-microphone);
}

#target>div.vac-card-window>div>div.vac-col-messages>div.vac-room-header.vac-app-border-b>div>div.vac-menu-options>div>div:nth-child(1)>div {
  color: white;
  background-color: #f14668;
  font-weight: bold;
}

#target>div.vac-card-window>div>div.vac-col-messages>div.vac-room-header.vac-app-border-b>div>div.vac-menu-options>div>div:nth-child(5)>div {
  color: white;
  background-color: #00d1b2;
  font-weight: bold;
}

.vac-menu-list {
  padding: 0px;
}

.vac-icon-deleted {
  display: none;
}

.snackbar {
  background-color: rgba(54, 54, 54, 0.8) !important;
}

.snackbar>.action>.button {
  background-color: transparent !important;
  font-weight: 900 !important;
}

.vac-room-item .chip {
  color: #2f4058 !important;
  background-color: transparent !important;
  box-shadow: none !important;
  padding: 4px 0px !important;
}

.menu-lateral {
  display:flex;
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}

.lateral-order {
  padding: 1em;
}

.lateral-search {
  width: 25%;
  border: 1px solid #e1e4e8;
  height: calc(100vh - 121px);
}

.highlight-message {
  background-color: #a8b4a526;
  border-radius: 10px;
  transition: background-color 0.5s ease;
}

.transfer-note {
  .vac-message-card {
    background-color: rgb(245 235 116 / 71%) !important
  }
  div.vac-text-timestamp > span svg, .vac-message-actions-wrapper {
    display: none;
  }
}

.blink-green {
  animation: blink 1s infinite alternate;
}

@keyframes blink {
  from {
    background-color: transparent;
  }
  to {
    background-color: #cdf2d1;
  }
}
</style>
