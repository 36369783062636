var render = function render(){var _vm=this,_c=_vm._self._c;return _c('chat-window',{staticClass:"chat-window",attrs:{"single-room":true,"rooms":_vm.rooms,"current-user-id":_vm.getUserId,"messages":_vm.messages,"height":"calc(70vh - 100px)","loading-rooms":false,"rooms-loaded":true,"messages-loaded":_vm.messagesLoaded,"show-footer":false,"message-actions":[],"show-reaction-emojis":false,"text-messages":{
                ROOMS_EMPTY: 'Sem tickets',
                ROOM_EMPTY: 'Nenhum ticket selecionado',
                NEW_MESSAGES: 'Novas mensagens',
                MESSAGE_DELETED: 'Esta mensagem foi apagada',
                MESSAGES_EMPTY: 'Nenhuma mensagem',
                CONVERSATION_STARTED: '',
                TYPE_MESSAGE: 'Digite sua mensagem',
                SEARCH: 'Procurar ticket',
                IS_ONLINE: 'Está online',
                LAST_SEEN: 'Última visualização ',
                IS_TYPING: 'Está digitando...',
              }}})
}
var staticRenderFns = []

export { render, staticRenderFns }