<template>
    <chat-window class="chat-window" :single-room="true" :rooms="rooms" :current-user-id="getUserId"
                  :messages="messages" height="calc(70vh - 100px)" :loading-rooms="false" :rooms-loaded="true"
                  :messages-loaded="messagesLoaded" :show-footer="false" :message-actions="[]"
                  :show-reaction-emojis="false" :text-messages="{
                    ROOMS_EMPTY: 'Sem tickets',
                    ROOM_EMPTY: 'Nenhum ticket selecionado',
                    NEW_MESSAGES: 'Novas mensagens',
                    MESSAGE_DELETED: 'Esta mensagem foi apagada',
                    MESSAGES_EMPTY: 'Nenhuma mensagem',
                    CONVERSATION_STARTED: '',
                    TYPE_MESSAGE: 'Digite sua mensagem',
                    SEARCH: 'Procurar ticket',
                    IS_ONLINE: 'Está online',
                    LAST_SEEN: 'Última visualização ',
                    IS_TYPING: 'Está digitando...',
                  }" />
</template>
  
<script>
import mixin from '../../utils/mixins'
import ChatWindow from 'vue-advanced-chat'
import { mapGetters } from 'vuex'
import { enumContentFormat, enumContentType } from '../../utils/constants'
  
  export default {
    name: 'TemplateChat',
    props: {
      messages: {
        type: Array,
        default: () => []
      },
      rooms: {
        type: Array,
        default: () => []
      },
    },
    mixins: [mixin],
    components: {
        ChatWindow
    },
    computed: {
    ...mapGetters(['getUserId']),
    },
    data () {
      return {
        messagesLoaded: true,
      }
    },
  }
  </script>
  
  <style lang="scss" scoped>
  </style>
  